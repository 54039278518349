<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style>
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

*{
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

#app {
  font-family: Pretendard-Regular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
  padding-bottom: 15vmin;
}

h1{
  padding-top: 7vmin;
  padding-bottom: 7vmin;
  font-weight: 700;
  font-size: 7vmin;
  animation-name: tracking-in-expand;
  -webkit-animation-name: tracking-in-expand;
  animation-duration: 0.7s;
  animation-play-state: initial;
}

</style>
