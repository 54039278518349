import { createStore } from 'vuex'

export default createStore({
  state: {
    // 데이터
    appInfoList : []
  },
  getters: {
    // read-only인 getter 메소드
  },
  mutations: {
    // setter에 가까운...: state를 변경하는 메소드
    addAppInfo(state, payload) {
      // 두 번째 인자로는 변경될 값이 들어옴
      state.appInfoList.push(...payload);
    }
  },
  actions: {
    // mutation들을 호출하는 로직
    // 비동기로 http 요청, 받을 때 mutation을 호출해서 state를 바꿈
    // mutation을 활용하는 코드
    // C#에서도 setter가 있지만 setter를 활용하는 코드는 따로 있듯이..!

    initInfos({state, commit}) {
      // state: state에 대한 접근자(객체)
      // commit: mutation에 대한 접근자(메소드)

      // '사용할 메소드명', payload 넣어주기
      commit('addAppInfo', 
        [
          {
            appName : 'IdleCatHotel',  // 키는 따옴표 없어도 되지만 value는 따옴표 있어야..!
            appLink : 'https://play.google.com/store/games',
            appDesc : '귀여운 고양이 호텔 키우기',
            appImage : require('@/assets/idlecathotelAppIcon.png')
          },
          {
            appName : 'IdleMagicianRPG',
            appLink : 'https://play.google.com/store/games',
            appDesc : '마법사 키우기',
            appImage : require('@/assets/Pug.png')
          }
        ])
    }
  },
  // modules: {
    // 새로 추가된 듯?
  // }
})
